import type { ReactNode, ReactElement } from "react";
import React, { createContext, useContext } from "react";
import { trimStart, trimEnd } from "lodash-es";

const Context = createContext<string | undefined>(undefined);

type DomainProviderProps = {
	readonly children: ReactNode;
	readonly domain: string;
};

export function DomainProvider({
	children,
	domain,
}: DomainProviderProps): ReactElement {
	return <Context.Provider value={domain}>{children}</Context.Provider>;
}

export function useAbsoluteUrl(path: string): string {
	const domain = useContext(Context);
	if (!domain) {
		throw new Error("No domain context");
	}
	return `https://${trimEnd(domain, "/")}/${trimStart(path, "/")}`;
}
