import type { ReactNode } from "react";
import React, { useEffect } from "react";

type GorgiasProviderProps = {
	readonly id: string;
	readonly children: ReactNode;
};

function GorgiasProvider({ id, children }: GorgiasProviderProps) {
	useEffect(() => {
		const script = document.createElement("script");
		script.setAttribute("id", "gorgias-chat-widget-install-v3");
		script.setAttribute(
			"src",
			`https://config.gorgias.chat/bundle-loader/${id}`,
		);
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [id]);

	return <>{children}</>;
}

export { GorgiasProvider };
