import type { ReactElement, ReactNode } from "react";
import React, { createContext, useContext } from "react";

import type { GtmService } from "frontend-common";

export const GtmServiceContext = createContext<GtmService | undefined>(
	undefined,
);

type GtmServiceProviderProps = {
	readonly gtm: GtmService;
	readonly children: ReactNode;
};

export function GtmServiceProvider({
	gtm,
	children,
}: GtmServiceProviderProps): ReactElement {
	return (
		<GtmServiceContext.Provider value={gtm}>
			{children}
		</GtmServiceContext.Provider>
	);
}

export function useGtmService(): GtmService {
	const gtm = useContext(GtmServiceContext);
	if (!gtm) {
		throw new Error("No gtm service context");
	}
	return gtm;
}
