import React, {
	useState,
	useLayoutEffect,
	useCallback,
	useEffect,
} from "react";
import { Link, useLocation, NavLink } from "react-router-dom";
import { clsx } from "clsx";
import useEffectOnce from "react-use/lib/useEffectOnce";
import { useWindowEvent } from "hooks/ui";
import { VectorIcon } from "components/graphics";
import {
	useAuthenticationState,
	getSignedInInitials,
	isSignedInAuthenticationResult,
	isLoadingAuthenticationResult,
	isSignedOutAuthenticationResult,
} from "hooks/auth-state";
import { useWebsiteUrl } from "contexts";

function Header() {
	// scrolled state
	const [isScrolled, setScrolled] = useState(false);
	const updateScroll = useCallback(() => {
		setScrolled(window.scrollY > 50);
	}, []);
	useWindowEvent("scroll", updateScroll);
	useEffectOnce(updateScroll);

	// css requires access to scroll width
	useWindowEvent(
		"resize",
		useCallback(() => {
			const scrollbarWidth =
				window.innerWidth - document.documentElement.clientWidth;
			document.documentElement.style.setProperty(
				"--scrollbar-width",
				`${scrollbarWidth}px`,
			);
		}, []),
	);

	// Menu toggle
	const [isMenuOpen, setMenuOpen] = useState(false);
	useEffect(() => {
		document.body.classList.toggle("no-scroll", isMenuOpen);
	}, [isMenuOpen]);
	const onMenuOpenClick = () => {
		setMenuOpen((p) => !p);
	};
	const { pathname } = useLocation();
	useLayoutEffect(() => {
		setMenuOpen(false);
	}, [pathname]);

	// Links
	const websiteUrl = useWebsiteUrl();

	// Auth
	const authResult = useAuthenticationState();

	return (
		<div className="r-main-header-outer js-r-main-header-outer">
			<header
				className={clsx("r-main-header js-r-main-header", {
					"is-scrolled": isScrolled,
				})}
			>
				<div className="r-main-header__container container">
					<div className="r-main-header__content">
						<span className="r-main-header__menu-opener">
							<button
								type="button"
								className={clsx("r-menu-opener", { active: isMenuOpen })}
								onClick={onMenuOpenClick}
							>
								<span className="r-menu-opener__inner">
									<span className="r-menu-opener__lines">
										<span className="r-menu-opener__lines-item" />
									</span>
								</span>
							</button>
						</span>

						<div className="r-main-header__logo">
							<Link
								to="/"
								className="r-main-header__logo-link"
								aria-label="Go To The Brick Me Homepage"
							>
								<div
									className={clsx(
										"r-main-header__logo-img logo-animated js-r-main-header__logo-img",
										{ "logo-animated_thin": isScrolled && !isMenuOpen },
									)}
								>
									<svg
										className="logo-animated__part logo-animated__part_n_1"
										xmlns="http://www.w3.org/2000/svg"
										width="83.99"
										height="40.58"
										viewBox="0 0 83.99 40.58"
									>
										<path
											className="d-color-change d-color-change_red"
											fill="#3987e0"
											d="M0 0h40.58v40.58H0z"
										/>
										<path
											d="M12.27 12a2.36 2.36 0 012.28-2.45h5.95c3.73 0 6.06 2.3 6.06 5.63A4.83 4.83 0 0124 19.26a5.2 5.2 0 014.33 5.3c0 3.42-2.21 6.51-7.5 6.51h-6.11a2.37 2.37 0 01-2.45-2.28V12zm4.9 6h2.24c1.39 0 2.24-.7 2.24-2s-.81-2-2.24-2h-2.24zm0 8.78h3.09c1.63 0 3-.58 3-2.21s-1.51-2.21-3-2.21h-3.09z"
											fill="#fff"
										/>
										<path
											className="d-color-change d-color-change_blue"
											fill="#e83041"
											d="M43.41 0h40.58v40.58H43.41z"
										/>
										<path
											d="M55.78 11.88a2.37 2.37 0 012.28-2.45h6.07c4.36 0 7.2 2.67 7.2 6.69a5.86 5.86 0 01-5 6.05l4.27 4.6a3 3 0 011 2.12 2.42 2.42 0 01-2.51 2.27A2.52 2.52 0 0166.76 30l-6-7.6h-.06v6.2a2.46 2.46 0 01-4.9.35 2 2 0 010-.35zm4.9 6.84h3.24A2.38 2.38 0 1064.2 14h-3.52z"
											fill="#fff"
										/>
									</svg>
									<svg
										className="logo-animated__part logo-animated__part_n_2"
										xmlns="http://www.w3.org/2000/svg"
										width="127.39"
										height="40.58"
										viewBox="0 0 127.39 40.58"
									>
										<path
											className="m-color-change m-color-change_blue"
											fill="#f7b907"
											d="M43.41 0h40.58v40.58H43.41z"
										/>
										<path
											d="M65.69 9c3 0 6.51.85 6.51 3.42a2.23 2.23 0 01-2.12 2.33c-1.15 0-2.36-1-4.26-1-4 0-5.54 3.21-5.54 6.54s1.75 6.36 5.11 6.36c3.06 0 3.54-1 4.82-1a2.07 2.07 0 012.18 2 2.81 2.81 0 010 .29c0 2.69-4.08 3.63-6.78 3.63C58.82 31.55 55 26.46 55 20.29 55 13.75 59.22 9 65.69 9"
											fill="#fff"
										/>
										<path
											className="m-color-change m-color-change_red"
											fill="#3987e0"
											d="M86.81 0h40.58v40.58H86.81z"
										/>
										<path
											d="M98.43 11.94a2.46 2.46 0 014.9-.35 2 2 0 010 .35v6l6.75-7.35a2.76 2.76 0 012.3-1.25 2.6 2.6 0 012.45 2.37 3 3 0 01-1 2L108 19.53l6.39 6.94c.76.81 1.36 1.45 1.36 2.36a2.38 2.38 0 01-2.35 2.42 1.62 1.62 0 01-.22 0c-1.3 0-2.24-1.18-3-2.06l-6.84-7.69v7.14a2.46 2.46 0 11-4.9.35 2 2 0 010-.35z"
											fill="#fff"
										/>
										<path
											className="m-color-change m-color-change_yellow"
											fill="#e83041"
											d="M0 0h40.58v40.58H0z"
										/>
										<path
											d="M22.74 28.65a2.46 2.46 0 11-4.9.34v-17a2.46 2.46 0 014.9-.35 2 2 0 010 .35z"
											fill="#fff"
										/>
									</svg>
									<svg
										className="logo-animated__part logo-animated__part_n_3"
										xmlns="http://www.w3.org/2000/svg"
										width="127.39"
										height="40.58"
										viewBox="0 0 127.39 40.58"
									>
										<path fill="#f7b907" d="M86.81 0h40.58v40.58H86.81z" />
										<path
											d="M100.81 12a2.36 2.36 0 012.28-2.45h7.68a2.19 2.19 0 012.43 1.93 1.88 1.88 0 010 .34 2.17 2.17 0 01-2 2.28 2.64 2.64 0 01-.4 0h-5.06V18h4.63a2.28 2.28 0 11.36 4.54 2.16 2.16 0 01-.36 0h-4.63v4H111a2.18 2.18 0 012.43 1.87 2.68 2.68 0 010 .4 2.21 2.21 0 01-2.12 2.29 1.83 1.83 0 01-.33 0h-7.69a2.35 2.35 0 01-2.45-2.27.54.54 0 010-.18z"
											fill="#fff"
										/>
										<path fill="#e83041" d="M43.4 0h40.58v40.58H43.4z" />
										<path
											d="M53.8 12.42c.45-2.57 2-3.09 3.24-3.09 2.45 0 3 1.43 4.24 5.3l2.39 7.42h.06l2.39-7.42c1.24-3.87 1.78-5.3 4.24-5.3 1.24 0 2.78.52 3.23 3.09l1.94 11.08c.39 2.24.82 4.69.82 5.42a2.32 2.32 0 01-2.3 2.33c-2.21 0-2.6-1.58-2.85-3.24l-1.45-9.56h-.06L66.39 29c-.48 1.57-1.21 2.21-2.7 2.21s-2.2-.6-2.69-2.21l-3.3-10.55h-.06L56.19 28c-.24 1.66-.64 3.24-2.85 3.24A2.33 2.33 0 0151 28.92c0-.73.43-3.18.82-5.42z"
											fill="#fff"
										/>
										<path fill="#3987e0" d="M0 0h40.58v40.58H0z" />
										<path
											d="M40.58 23.09L29 11.55 11.55 29 23.1 40.58h17.48z"
											fill="#0f6dda"
										/>
										<path
											d="M29 11.55a12.36 12.36 0 11-17.48 0 12.35 12.35 0 0117.48 0"
											fill="#73a5f7"
										/>
										<path
											d="M14 14a11.64 11.64 0 0111.19-3A10.51 10.51 0 0011 25.23 11.64 11.64 0 0114 14"
											fill="#c2e1ee"
										/>
									</svg>
								</div>
							</Link>
						</div>

						<nav
							className={clsx("r-main-header__menu r-main-menu", {
								active: isMenuOpen,
							})}
						>
							<div className="r-main-menu__inner">
								<ul
									className={clsx("r-main-menu__list", {
										active: isMenuOpen,
									})}
								>
									<li className="r-main-menu__item">
										<a href={websiteUrl} className="r-main-menu__link">
											&lt; Back to website
										</a>
									</li>
								</ul>
								<div className="r-main-menu__mobile-box">
									{isSignedInAuthenticationResult(authResult) && (
										<NavLink
											to="/my-account/"
											className="r-main-menu__mobile-item initials-btn"
										>
											{getSignedInInitials(authResult)}
										</NavLink>
									)}
									{isLoadingAuthenticationResult(authResult) && (
										<div className="r-main-menu__mobile-item icon-link">
											<VectorIcon iconName="user" className="icon-link__icon" />
											<span className="icon-link__text">Login</span>
										</div>
									)}
									{isSignedOutAuthenticationResult(authResult) && (
										<NavLink
											to="/login/"
											className="r-main-menu__mobile-item icon-link"
										>
											<VectorIcon iconName="user" className="icon-link__icon" />
											<span className="icon-link__text">Login</span>
										</NavLink>
									)}
								</div>
							</div>
						</nav>
						<aside className="r-main-header__aside">
							{isSignedInAuthenticationResult(authResult) && (
								<NavLink
									to="/my-account/"
									className="icon-btn initials-btn"
									aria-label="Login"
									title="Login"
								>
									{getSignedInInitials(authResult)}
								</NavLink>
							)}
							{isLoadingAuthenticationResult(authResult) && (
								<div className="r-main-header__login icon-btn">
									<VectorIcon iconName="user" className="icon-btn__icon" />
								</div>
							)}
							{isSignedOutAuthenticationResult(authResult) && (
								<NavLink
									to="/login/"
									className="r-main-header__login icon-btn"
									aria-label="Login"
									title="Login"
								>
									<VectorIcon iconName="user" className="icon-btn__icon" />
								</NavLink>
							)}
						</aside>
					</div>
				</div>
			</header>
		</div>
	);
}

export default Header;
