import type { ReactElement } from "react";
import React from "react";

function LoadingSpinner({
	loadingText,
}: {
	loadingText?: string;
}): ReactElement {
	return (
		<div className="loading-more" style={{ justifyContent: "center" }}>
			<div className="loading-more__spinner">
				<div className="lds-ring">
					<div />
					<div />
					<div />
					<div />
				</div>
			</div>
			<span className="loading-more__text">{loadingText || "Loading ..."}</span>
		</div>
	);
}

export default LoadingSpinner;
