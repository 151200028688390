import type { CognitoUserSession } from "amazon-cognito-identity-js";
import {
	CognitoUserPool,
	CognitoUser,
	AuthenticationDetails,
	CookieStorage,
	CognitoRefreshToken,
	CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import type {
	AuthUser,
	AuthProfile,
	CompleteAuthUser,
	AccessToken,
} from "./auth-user";
import {
	authenticateUser,
	signUp,
	updateAttributes,
	getUserAttributes,
	getSession,
	isAwsError,
	forgotPassword,
	confirmPassword,
	changePassword,
} from "./cognito-promise";
import {
	profileToCognitoAttributes,
	cognitoAttributesToProfile,
} from "./mapping";

type Config = {
	readonly userPoolId: string;
	readonly appClientId: string;
	readonly cookieDomain: string;
	readonly originSite: string;
};

type UserPair<TUser extends AuthUser = AuthUser> = {
	readonly authUser: TUser;
	readonly cognitoUser: CognitoUser;
};

function createAuthService({
	userPoolId,
	appClientId,
	cookieDomain,
	originSite,
}: Config) {
	// Should be secure, but makes local testing a pain. TODO: Pass it in as an arg
	const storage = new CookieStorage({
		secure: false,
		domain: cookieDomain,
	});
	const userPool = new CognitoUserPool({
		UserPoolId: userPoolId,
		ClientId: appClientId,
		Storage: storage,
	});

	async function signInPromise(
		emailAddress: string,
		password: string,
	): Promise<{ authUser: AuthUser; cognitoUser: CognitoUser }> {
		const cognitoUser = new CognitoUser({
			Username: emailAddress,
			Pool: userPool,
			Storage: storage,
		});
		const session = await authenticateUser(
			cognitoUser,
			new AuthenticationDetails({
				Username: emailAddress,
				Password: password,
			}),
		);
		const accessToken = session.getAccessToken();
		const attrs = await getUserAttributes(cognitoUser);
		return {
			authUser: {
				id: cognitoUser.getUsername(),
				emailAddress,
				jwtRefreshToken: {
					token: session.getRefreshToken().getToken(),
				},
				jwtAccessToken: {
					token: accessToken.getJwtToken(),
					expiry: accessToken.getExpiration() * 1000,
				},
				...cognitoAttributesToProfile(attrs),
			},
			cognitoUser,
		};
	}

	return {
		async signUp(
			emailAddress: string,
			password: string,
			profile: Omit<AuthProfile, "originSite" | "currentSite">,
		): Promise<UserPair<CompleteAuthUser>> {
			const cognitoUser = await signUp(
				userPool,
				emailAddress,
				password,
				profileToCognitoAttributes({
					originSite,
					currentSite: originSite,
					...profile,
				}),
			);
			if (!cognitoUser) {
				throw new Error("Account already exists for this email address");
			}
			const { authUser } = await signInPromise(emailAddress, password);
			return {
				authUser: {
					...authUser,
					...profile,
					originSite,
					currentSite: originSite,
				},
				cognitoUser,
			};
		},
		async refreshAccessToken(refreshToken: string): Promise<AccessToken> {
			const user = userPool.getCurrentUser();
			if (!user) {
				throw new Error("Current user required");
			}
			return new Promise<AccessToken>((resolve, reject) => {
				user.refreshSession(
					new CognitoRefreshToken({ RefreshToken: refreshToken }),
					(error?: Error, session?: CognitoUserSession) => {
						if (error) {
							reject(error);
							return;
						}
						if (!session) {
							reject(new Error("No session"));
							return;
						}

						const accessToken = session.getAccessToken();
						resolve({
							token: accessToken.getJwtToken(),
							expiry: accessToken.getExpiration() * 1000,
						});
					},
				);
			});
		},
		async updateAttributes(cognitoUser: CognitoUser, profile: AuthProfile) {
			await updateAttributes(cognitoUser, profileToCognitoAttributes(profile));
		},
		async loadCurrentUser(): Promise<UserPair | undefined> {
			const cognitoUser = userPool.getCurrentUser();
			if (!cognitoUser) {
				return undefined;
			}

			// refresh the session if the session expired.
			const session = await getSession(cognitoUser);
			let userAttributes;
			try {
				userAttributes = await getUserAttributes(cognitoUser);
			} catch (e) {
				// User has been deleted. Probably shouldn't happen
				if (
					isAwsError(e as any) &&
					(e as any).code === "UserNotFoundException"
				) {
					cognitoUser.signOut();
					return undefined;
				}
				throw e;
			}

			const attrs: Record<string, string> = {};
			for (const attr of userAttributes ?? []) {
				attrs[attr.getName()] = attr.getValue();
			}
			if (attrs["custom:current_site"] !== originSite) {
				await new Promise<void>((resolve, reject) => {
					cognitoUser.updateAttributes(
						[
							new CognitoUserAttribute({
								Name: "custom:current_site",
								Value: originSite,
							}),
						],
						(error) => {
							if (error) {
								reject(error);
								return;
							}
							resolve();
						},
					);
				});
				attrs["custom:current_site"] = originSite;
			}

			const accessToken = session.getAccessToken();
			return {
				authUser: {
					id: cognitoUser.getUsername(),
					emailAddress: attrs.email,
					jwtRefreshToken: {
						token: session.getRefreshToken().getToken(),
					},
					jwtAccessToken: {
						token: accessToken.getJwtToken(),
						expiry: accessToken.getExpiration() * 1000,
					},
					...cognitoAttributesToProfile(userAttributes),
				},
				cognitoUser,
			};
		},
		signOutCurrentUser(): void {
			const current = userPool.getCurrentUser();
			if (!current) {
				return;
			}
			current.signOut();
		},
		async signIn(emailAddress: string, password: string): Promise<UserPair> {
			return signInPromise(emailAddress, password);
		},
		async forgotPassword(emailAddress: string) {
			const user = new CognitoUser({
				Username: emailAddress,
				Pool: userPool,
				Storage: storage,
			});
			await forgotPassword(user);
		},
		async confirmPassword(
			emailAddress: string,
			verificationCode: string,
			password: string,
		) {
			const user = new CognitoUser({
				Username: emailAddress,
				Pool: userPool,
				Storage: storage,
			});
			await confirmPassword(user, verificationCode, password);
		},
		async changePassword(
			cognitoUser: CognitoUser,
			oldPassword: string,
			newPassword: string,
		) {
			await changePassword(cognitoUser, oldPassword, newPassword);
		},
	};
}

type AuthService = ReturnType<typeof createAuthService>;

export type { AuthService };
export default createAuthService;
