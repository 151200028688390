import type { StringSchema } from "yup";
import { setLocale, addMethod, string } from "yup";
import { z } from "zod";

setLocale({
	mixed: {
		required: "Required",
	},
	string: {
		email: "Invalid email address",
	},
	number: {
		positive: "Must be a positive number",
		integer: "Must be a number",
	},
});

function password(this: StringSchema): StringSchema {
	return (
		this.min(8, "Password must be at least 8 characters long")
			// See https://sentry.io/organizations/creatio/issues/3326618984/?project=5457693&query=is%3Aunresolved
			// cognito threw an error when password was just empty space.
			.matches(
				/^[\S]+.*[\S]+$/,
				"Password must contain at least one non-whitespace character",
			)
	);
	// See https://stackoverflow.com/questions/58767980/aws-cognito-password-regex
	// No requirements atm
	// .matches(
	//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{6,99}$/,
	//   'Must have a lower case letter, an upper case letter, a number and a symbol'
	// );
}
addMethod(string, "password", password);

function verificationCode(this: StringSchema, length: number): StringSchema {
	return this.length(length).matches(/^[0-9]+$/, "Code should be numbers only");
}
addMethod(string, "verificationCode", verificationCode);

function zPassword() {
	return (
		z
			.string()
			.min(8, "Password must be at least 8 characters long")
			// See https://sentry.io/organizations/creatio/issues/3326618984/?project=5457693&query=is%3Aunresolved
			// cognito threw an error when password was just empty space.
			.regex(
				/^[\S]+.*[\S]+$/,
				"Password must contain at least one non-whitespace character",
			)
	);
}

export { zPassword };
