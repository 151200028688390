import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import type { AuthProfile } from "./auth-user";

// const nameMappings: { readonly [key in keyof AuthProfile]: string } = {
// Mapping typing of object.entries didnt work

const nameMappings: ReadonlyArray<
	Readonly<[keyof AuthProfile, string, (value: string) => any, boolean]>
> = [
	["firstName", "given_name", String, true],
	["lastName", "family_name", String, true],
	["country", "custom:country", String, true],
	["howHeard", "custom:how_heard", String, true],
	["artProductCreator", "custom:art_product_creator", Boolean, false],
	["birthdate", "birthdate", String, true],
	["originSite", "custom:origin_site", String, true],
	["currentSite", "custom:current_site", String, true],
	["ipAddress", "custom:ip_address", String, true],
	["ipAddressCountry", "custom:ip_address_country", String, true],
];

function profileToCognitoAttributes(profile: AuthProfile) {
	return nameMappings
		.map(
			([profileField, attrName, , updatable]) =>
				[attrName, profile[profileField], updatable] as const,
		)
		.filter(([, value, updatable]) => value !== undefined && updatable)
		.map(([attrName, value]) => {
			const attrValue = value;
			// TODO: Should be taken care of by typing
			if (attrValue === undefined) {
				throw new Error("Undefined attr");
			}

			return new CognitoUserAttribute({
				Name: attrName,
				Value: attrValue.toString(),
			});
		});
}

type Writeable<T extends { [x: string]: any }, K extends string> = {
	[P in K]: T[P];
};

function cognitoAttributesToProfile(
	attributes: ReadonlyArray<CognitoUserAttribute>,
): Partial<AuthProfile> {
	const profile: Partial<Writeable<AuthProfile, keyof AuthProfile>> = {};
	nameMappings.forEach(([profileField, attrName, constructor]) => {
		const attr = attributes.find((a) => a.getName() === attrName);
		if (!attr) {
			return;
		}

		profile[profileField] = constructor(attr.getValue());
	});
	return profile;
}

export { cognitoAttributesToProfile, profileToCognitoAttributes };
