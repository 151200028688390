import type { ReactNode, ReactElement } from "react";
import React, { createContext, useContext } from "react";

const Context = createContext<{ appId?: string } | undefined>(undefined);

type FacebookProviderProps = {
	readonly children: ReactNode;
	readonly appId?: string;
};

export function FacebookProvider({
	children,
	appId,
}: FacebookProviderProps): ReactElement {
	return <Context.Provider value={{ appId }}>{children}</Context.Provider>;
}

export function useFacebookAppId(): string | undefined {
	const value = useContext(Context);
	if (!value) {
		throw new Error("No context provided");
	}
	return value.appId;
}
