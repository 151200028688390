import "./cra-5-polyfill.ts";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";
import "intersection-observer";
import React, { Fragment, StrictMode } from "react";
import { render } from "react-dom";
import ReactModal from "react-modal";
import "validations.ts";
import AppContainer from "app/app-container.tsx";
import { init as initSentry, browserTracingIntegration } from "@sentry/react";
import {
	createStubGtm,
	initialiseGtm,
	PasswordProtected,
} from "frontend-common";
import { ensureEnv, optionalEnv } from "utils/env";
import "assets-new/sass/main.sass";

if (!(window as any).ResizeObserver) {
	(async () => {
		(window as any).ResizeObserver = (
			await import("resize-observer-polyfill")
		).default;
	})();
}

ReactModal.setAppElement("#root");

const sentryDsn = optionalEnv("SENTRY_DSN");
const sentryEnvironment = optionalEnv("SENTRY_ENVIRONMENT");
if (sentryDsn) {
	initSentry({
		dsn: sentryDsn,
		environment: sentryEnvironment,
		integrations: [browserTracingIntegration()],
		normalizeDepth: 6,

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		// tracesSampleRate: 1.0,
	});
}

const shopifyCustomDomain = ensureEnv("SHOPIFY_SHOP_DOMAIN");
const shopifyStorefrontAccessToken = ensureEnv("STOREFRONT_ACCESS_TOKEN");
const userPoolId = ensureEnv("USER_POOL_ID");
const userPoolAppClientId = ensureEnv("USER_POOL_APP_CLIENT_ID");
const apiUrl = ensureEnv("API_URL");
const platformCookieDomain = ensureEnv("PLATFORM_COOKIE_DOMAIN");
const creatorAppUrl = ensureEnv("CREATOR_APP_URL");
const websiteUrl = ensureEnv("WEBSITE_URL");
const gorgiasId = ensureEnv("GORGIAS_ID");

const contactEmailAddress = ensureEnv("CONTACT_EMAIL_ADDRESS");
// const ipInfoToken = ensureEnv("IP_INFO_TOKEN");
const domain = ensureEnv("DOMAIN");
const facebookAppId = optionalEnv("FACEBOOK_APP_ID");

const PossiblePasswordProtected = optionalEnv("PROTECTED_PASSWORD")
	? PasswordProtected
	: Fragment;

const useGtmId = optionalEnv("GTM_ID");
const gtm = useGtmId ? initialiseGtm({ gtmId: useGtmId }) : createStubGtm();

function Root() {
	return (
		<StrictMode>
			<PossiblePasswordProtected>
				<AppContainer
					originSite={ensureEnv("SITE_CODE")}
					contactEmailAddress={contactEmailAddress}
					gtm={gtm}
					// ipInfoToken={ipInfoToken}
					creatorAppUrl={creatorAppUrl}
					websiteUrl={websiteUrl}
					shopifyConfig={{
						customDomain: shopifyCustomDomain,
						storefrontAccessToken: shopifyStorefrontAccessToken,
					}}
					userPoolConfig={{
						id: userPoolId,
						appClientId: userPoolAppClientId,
					}}
					// countryRedirectRules={countryRedirectRules}
					// defaultCountryCode={defaultCountryCode}
					apiUrl={apiUrl}
					platformCookieDomain={platformCookieDomain}
					gorgiasId={gorgiasId}
					domain={domain}
					facebookAppId={facebookAppId}
				/>
			</PossiblePasswordProtected>
		</StrictMode>
	);
}

const rootElement = document.getElementById("root");
render(<Root />, rootElement);
