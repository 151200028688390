import type { ReactElement, ReactNode } from "react";
import React, { createContext, useContext, useMemo } from "react";
import type {
	QueryHookOptions,
	QueryResult,
	MutationTuple,
	MutationHookOptions,
} from "@apollo/client/react";
import { useQuery, useMutation } from "@apollo/client/react";
import type {
	OperationVariables,
	DocumentNode,
	DefaultContext,
} from "@apollo/client/core";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client/core";
import timeoutPromise from "utils/timeout-promise";

export const Context = createContext<ApolloClient<object> | undefined>(
	undefined,
);

type ShopifyGraphQlProviderProps = {
	readonly shopDomain: string;
	readonly storefrontAccessToken: string;
	readonly children: ReactNode;
};

async function hasTooManyRequestsError(response: Response): Promise<boolean> {
	try {
		const result = await response.clone().json();
		if (!result || !result.errors || result.errors.length === 0) {
			return false;
		}
		return result.errors[0].message.indexOf("Too many requests.") === 0;
	} catch {
		// Let downstream handle it
		return false;
	}
}

async function retryAttempt(
	attemptNum: number,
	...args: Parameters<typeof fetch>
): Promise<Response> {
	const response = await fetch(...args);
	if (attemptNum >= 3) {
		return response;
	}

	const hasTooMany = await hasTooManyRequestsError(response);
	if (!hasTooMany) {
		return response;
	}

	// Adds a bit of jitter
	await timeoutPromise((attemptNum + 1) * 300 + Math.random() * 200);
	return retryAttempt(attemptNum + 1, ...args);
}

async function retryTooManyRequestsFetch(
	...args: Parameters<typeof fetch>
): Promise<Response> {
	return retryAttempt(0, ...args);
}

// Wasn't able to get this working
/*
Link.from([
new RetryLink({
  delay: {
    initial: 400, // 0.5s is the wait period for storefront api limiting
    max: Infinity,
    jitter: false,
  },
  attempts: {
    max: 4,
    retryIf(error) {
      if (!error) {
        return false;
      }
      // Note: haven't been able to test this yet
      // Too many requests. Please try again in a few seconds
      const message = error.toString();
      return message.indexOf("Too many requests") >= 0;
    },
  },
}),
httpLink
 */

function ShopifyGraphQlProvider({
	shopDomain,
	storefrontAccessToken,
	children,
}: ShopifyGraphQlProviderProps): ReactElement {
	const client = useMemo(
		() =>
			new ApolloClient({
				link: new HttpLink({
					uri: `https://${shopDomain}/api/2022-10/graphql.json`,
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						"X-Shopify-Storefront-Access-Token": storefrontAccessToken,
					},
					fetch: retryTooManyRequestsFetch,
				}),
				cache: new InMemoryCache({
					typePolicies: {
						ProductVariant: {
							merge: true,
						},
					},
				}),
			}),
		[shopDomain, storefrontAccessToken],
	);

	return <Context.Provider value={client}>{children}</Context.Provider>;
}

function useShopifyApolloClient(): ApolloClient<object> {
	const client = useContext(Context);
	if (!client) {
		throw new Error("No shopify graphql context");
	}
	return client;
}

function useShopifyQuery<TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	options?: Omit<QueryHookOptions<TData, TVariables>, "client">,
): QueryResult<TData, TVariables> {
	const client = useShopifyApolloClient();
	return useQuery<TData, TVariables>(query, {
		...options,
		// This makes sure loading is updated when fetchMore
		notifyOnNetworkStatusChange: true,
		client,
	});
}

function useShopifyMutation<
	TData = any,
	TVariables = OperationVariables,
	TContext = DefaultContext,
>(
	mutation: DocumentNode,
	options?: Omit<MutationHookOptions<TData, TVariables, TContext>, "client">,
): MutationTuple<TData, TVariables, TContext> {
	const client = useShopifyApolloClient();
	return useMutation<TData, TVariables, TContext>(mutation, {
		...options,
		client,
	});
}

export {
	ShopifyGraphQlProvider,
	useShopifyApolloClient,
	useShopifyQuery,
	useShopifyMutation,
};
