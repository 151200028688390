import type { ReactNode } from "react";
import React, { createContext, useCallback, useContext, useState } from "react";
import type { CognitoUser } from "amazon-cognito-identity-js";
import type { AuthUser } from "frontend-common";

export type UserPair = {
	readonly authUser: AuthUser;
	readonly cognitoUser: CognitoUser;
};

export type AuthenticationState = "loading" | "anonymous" | UserPair;

type UserState = {
	readonly result: AuthenticationState;
	readonly setResult: (result: AuthenticationState) => void;
};

export const AuthStateContext = createContext<UserState | undefined>(undefined);

type AuthStateProviderProps = {
	readonly children: ReactNode;
};

export function AuthStateProvider({ children }: AuthStateProviderProps) {
	const [result, setResult] = useState<UserState["result"]>("loading");
	return (
		<AuthStateContext.Provider value={{ result, setResult }}>
			{children}
		</AuthStateContext.Provider>
	);
}

export type SignedInAuthenticationResult = {
	readonly authUser: AuthUser;
	readonly cognitoUser: CognitoUser;
	readonly unsetAuthenticatedUser: () => void;
	readonly setAuthenticatedUser: (users: UserPair) => void;
	readonly updateAuthenticatedUser: (user: Partial<AuthUser>) => void;
};

type SignedOutAuthenticationResult = {
	readonly setAuthenticatedUser: (users: UserPair) => void;
};

type LoadingAuthenticationResult = {
	readonly onUserLoaded: (user?: UserPair) => void;
};

export type AuthenticationResult =
	| LoadingAuthenticationResult
	| SignedInAuthenticationResult
	| SignedOutAuthenticationResult;

export function isLoadingAuthenticationResult(
	result: AuthenticationResult,
): result is LoadingAuthenticationResult {
	return typeof result === "object" && "onUserLoaded" in result;
}

export function isSignedInAuthenticationResult(
	result: AuthenticationResult,
): result is SignedInAuthenticationResult {
	return typeof result === "object" && "authUser" in result;
}

function getSignedInInitials(result: SignedInAuthenticationResult) {
	if (result.authUser.firstName && result.authUser.lastName) {
		return (
			result.authUser.firstName.charAt(0) + result.authUser.lastName.charAt(0)
		);
	}
	return result.authUser.emailAddress.slice(0, 2);
}

export function isSignedOutAuthenticationResult(
	result: AuthenticationResult,
): result is SignedOutAuthenticationResult {
	return (
		!isSignedInAuthenticationResult(result) &&
		!isLoadingAuthenticationResult(result)
	);
}

export function useAuthenticationState(): AuthenticationResult {
	const userState = useContext(AuthStateContext);
	if (userState === undefined) {
		throw new Error("Auth context missing");
	}
	const { result, setResult } = userState;

	const updateAuthenticatedUser = useCallback(
		(updates: Partial<AuthUser>): void => {
			if (result === "loading" || result === "anonymous") {
				throw new Error("Invalid state");
			}
			setResult({
				cognitoUser: result.cognitoUser,
				authUser: { ...result.authUser, ...updates },
			});
		},
		[setResult, result],
	);

	const onUserLoaded = useCallback(
		(user?: UserPair) => {
			setResult(user || "anonymous");
		},
		[setResult],
	);

	const unsetAuthenticatedUser = useCallback(() => {
		setResult("anonymous");
	}, [setResult]);

	if (result === "loading") {
		return { onUserLoaded };
	}
	if (result === "anonymous") {
		return {
			setAuthenticatedUser: setResult,
		};
	}
	return {
		authUser: result.authUser,
		cognitoUser: result.cognitoUser,
		unsetAuthenticatedUser,
		setAuthenticatedUser: setResult,
		updateAuthenticatedUser,
	};
}

/**
 * Helper mainly for Typescript, but also for catching any issues loudly.
 * Should use in private only pages.
 */
export function useSignedInAuthenticationResult(): SignedInAuthenticationResult {
	const result = useAuthenticationState();
	if (!isSignedInAuthenticationResult(result)) {
		throw new Error("User not in signed in state");
	}
	return result;
}

/**
 * Helper mainly for Typescript, but also for catching any issues loudly.
 * Should use in public only pages.
 */
export function useSignedOutAuthenticationResult(): SignedOutAuthenticationResult {
	const result = useAuthenticationState();
	if (!isSignedOutAuthenticationResult(result)) {
		throw new Error("User not in signed out state");
	}
	return result;
}

export { getSignedInInitials };
