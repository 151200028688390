import { Buffer } from "buffer";

if (typeof global.process === "undefined") {
	(global as any).process = {};
}

(global.process as any).browser = true;

global.Buffer = global.Buffer || Buffer;

export {};
