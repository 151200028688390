import { useEffect } from "react";

function useWindowEvent(event: "scroll" | "resize", callback: () => void) {
	useEffect(() => {
		window.addEventListener(event, callback);
		return () => {
			window.removeEventListener(event, callback);
		};
	}, [event, callback]);
}

export { useWindowEvent };
