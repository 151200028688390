import type { AuthService } from "frontend-common/auth/create-auth-service";
import type { ReactElement, ReactNode } from "react";
import React, { createContext, useContext } from "react";

const AuthServiceContext = createContext<AuthService | undefined>(undefined);

type AuthClientProviderProps = {
	readonly authService: AuthService;
	readonly children: ReactNode;
};

function AuthServiceProvider({
	authService,
	children,
}: AuthClientProviderProps): ReactElement {
	return (
		<AuthServiceContext.Provider value={authService}>
			{children}
		</AuthServiceContext.Provider>
	);
}

function useAuthService(): AuthService {
	const authService = useContext(AuthServiceContext);
	if (!authService) {
		throw new Error("No auth service context");
	}
	return authService;
}

export { AuthServiceContext, AuthServiceProvider, useAuthService };
