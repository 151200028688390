import type { CSSProperties } from "react";
import React from "react";
import spriteSVG from "assets-new/img/svg/sprite.svg";

type VectorIconProps = {
	readonly className?: string;
	readonly iconName: string;
};

function VectorIcon({ iconName, className }: VectorIconProps) {
	return (
		<svg className={`sprite-icon ${className}`}>
			<use xlinkHref={`${spriteSVG}#${iconName}`} />
		</svg>
	);
}

function BrickMeLogoSVG({ className }: { className?: string }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 486.51 486.49"
			width={72}
			height={72}
			className={className}
		>
			<path fill="#3987e0" d="M0 0h154.98v154.98H0z" />
			<path
				d="M46.86 45.71a9 9 0 0 1 9.36-9.37H78.3c14.22 0 23.12 8.79 23.12 21.5 0 8.44-5.9 13.41-9.83 15.72 10.06 2 16.53 9.94 16.53 20.23 0 13.06-8.44 24.85-28.66 24.85H56.22a9 9 0 0 1-9.36-9.36zm18.73 22.77h8.55c5.32 0 8.55-2.66 8.55-7.75S79.57 53 74.14 53h-8.55zm0 33.52h11.79c6.24 0 11.32-2.2 11.32-8.44s-5.78-8.44-11.32-8.44H65.59z"
				fill="#fff"
			/>
			<path fill="#f7b907" d="M165.76 165.76h154.98v154.98H165.76z" />
			<path
				d="M250.88 200.26c11.33 0 24.85 3.23 24.85 13.06 0 4.51-3.23 8.9-8.09 8.9-4.39 0-9-3.93-16.29-3.93-15.15 0-21.16 12.25-21.16 25 0 13.07 6.71 24.28 19.54 24.28 11.67 0 13.52-3.82 18.38-3.82 7.39 0 8.32 6.25 8.32 8.67 0 10.29-15.61 13.87-25.89 13.87-25.89 0-40.46-19.42-40.46-43 0-25 16.07-43.03 40.8-43.03z"
				fill="#fff"
			/>
			<path fill="#3987e0" d="M331.53 165.76h154.98v154.98H331.53z" />
			<path
				d="M375.9 211.35c0-6.59 3.93-9.94 9.36-9.94s9.37 3.35 9.37 9.94v22.89l25.77-28.09c3.7-4.16 6-4.74 8.79-4.74 4.16 0 9.36 4.05 9.36 9 0 2.77-1.74 5.43-3.7 7.51l-22.31 22.43 24.39 26.46c2.89 3.13 5.2 5.55 5.2 9 0 6-4.62 9.25-9.82 9.25-5 0-8.56-4.51-11.56-7.86l-26.12-29.36v27.28c0 6.59-3.93 9.94-9.37 9.94s-9.36-3.35-9.36-9.94z"
				fill="#fff"
			/>
			<path fill="#f7b907" d="M331.53 331.51h154.98v154.98H331.53z" />
			<path
				d="M385 377.22a9 9 0 0 1 9.37-9.37H423c5.9 0 9.37 3.47 9.37 8.68 0 5.43-3.47 8.66-9.37 8.66h-19.3v14.91h17.68c5.9 0 9.37 3.36 9.37 8.67s-3.47 8.67-9.37 8.67H403.7v15.38h20c5.89 0 9.36 3.23 9.36 8.66 0 5.21-3.47 8.67-9.36 8.67h-29.36a9 9 0 0 1-9.37-9.36z"
				fill="#fff"
			/>
			<path fill="#e83041" d="M165.76 0h154.98v154.98H165.76z" />
			<path
				d="M213 45.36a9 9 0 0 1 9.39-9.36h22.54c16.65 0 27.51 10.18 27.51 25.55 0 12.14-7.4 20.92-19.19 23.12l16.3 17.57c2.54 2.77 3.93 5 3.93 8.09a9.24 9.24 0 0 1-9.59 8.67c-4.86 0-6.59-1.74-8.9-4.63l-23-29h-.24v23.7c0 6.59-3.93 9.94-9.36 9.94s-9.36-3.35-9.36-9.94zm18.72 26.12h12.37c6.13 0 9.6-4 9.6-9.25 0-5.54-4.28-8.9-9.6-8.9h-12.34z"
				fill="#fff"
			/>
			<path fill="#e83041" d="M0 165.76h154.98v154.98H0z" />
			<path
				d="M86.85 275.16c0 6.59-3.93 9.94-9.36 9.94s-9.36-3.35-9.36-9.94v-63.81c0-6.59 3.93-9.94 9.36-9.94s9.36 3.35 9.36 9.94z"
				fill="#fff"
			/>
			<path fill="#e83041" d="M165.76 331.51h154.98v154.98H165.76z" />
			<path
				d="M205.45 379c1.74-9.82 7.63-11.79 12.37-11.79 9.36 0 11.44 5.43 16.18 20.23l9.13 28.32h.24l9.13-28.32c4.73-14.8 6.82-20.23 16.18-20.23 4.74 0 10.63 2 12.37 11.79l7.39 42.31c1.51 8.55 3.12 17.91 3.12 20.69a8.85 8.85 0 0 1-8.78 8.9c-8.44 0-9.94-6-10.87-12.37L266.37 402h-.23l-12.6 40.46c-1.85 6-4.63 8.44-10.29 8.44s-8.44-2.43-10.29-8.44L220.36 402h-.23l-5.55 36.53c-.92 6.36-2.42 12.37-10.86 12.37a8.86 8.86 0 0 1-8.79-8.9c0-2.78 1.62-12.14 3.12-20.69z"
				fill="#fff"
			/>
			<path fill="#3987e0" d="M0 331.51h154.98v154.98H0z" />
			<path
				fill="#0f6dda"
				d="M154.98 419.71l-44.09-44.1L44.1 442.4l44.09 44.09h66.79v-66.78z"
			/>
			<circle cx="77.49" cy={409} r="47.23" fill="#73a5f7" />
			<path
				d="M53.61 385.13a44.52 44.52 0 0 1 42.74-11.59A40.17 40.17 0 0 0 42 427.87a44.53 44.53 0 0 1 11.61-42.74z"
				fill="#c2e1ee"
			/>
		</svg>
	);
}

type SectionDividerRenderProps = {
	className?: string;
	style?: CSSProperties;
};

// white --- red
function SectionDividerShapeRedWhiteSVG({
	className,
	style,
}: SectionDividerRenderProps) {
	return (
		<div className="bricks-between__container container">
			<div className="bricks-between__box">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="139.01"
					height="111.98"
					viewBox="0 0 139.01 111.98"
					className={`bricks-between__img ${className}`}
					style={style}
				>
					<path fill="#e83041" d="M55.6 27.8h27.8v28.51H55.6z" />
					<path
						fill="#bf2235"
						d="M0 55.6h27.8v27.8H0zM55.6 83.41h27.8v28.57H55.6z"
					/>
					<path fill="#3987e0" d="M83.41 0h27.8v27.8h-27.8z" />
					<path fill="#f7b907" d="M27.8 0h27.8v27.8H27.8z" />
					<path fill="#e83041" d="M111.21 27.8h27.8v28.51h-27.8z" />
					<path fill="#fff" d="M27.8 54.06h27.8v29.35H27.8z" />
				</svg>
			</div>
		</div>
	);
}

// red --- white
function SectionDividerShapeWhiteRedSVG({
	className,
	style,
}: SectionDividerRenderProps) {
	return (
		<div className="bricks-between__container container">
			<div className="bricks-between__box">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="139.01"
					height="111.98"
					viewBox="0 0 139.01 111.98"
					className={`bricks-between__img ${className}`}
					style={style}
				>
					<path fill="#e83041" d="M55.6 27.8h27.8v28.51H55.6z" />
					<path
						fill="#bf2235"
						d="M0 55.6h27.8v27.8H0zM55.6 83.41h27.8v28.57H55.6z"
					/>
					<path fill="#3987e0" d="M83.41 0h27.8v27.8h-27.8z" />
					<path fill="#f7b907" d="M27.8 0h27.8v27.8H27.8z" />
					<path fill="#e83041" d="M111.21 27.8h27.8v28.51h-27.8z" />
					<path fill="#fff" d="M27.8 54.06h27.8v29.35H27.8z" />
				</svg>
			</div>
		</div>
	);
}

// red --- blue
function SectionDividerShapeRedBlueSVG({
	className,
	style,
}: SectionDividerRenderProps) {
	return (
		<div className="bricks-between__container container">
			<div className="bricks-between__box">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="139.01"
					height="139.78"
					viewBox="0 0 139.01 139.78"
					className={`bricks-between__img ${className}`}
					style={style}
				>
					<path fill="#3987e0" d="M55.6 55.6h27.8v27.8H55.6z" />
					<path
						fill="#2a73bc"
						d="M0 81.86h27.8v29.35H0zM55.6 111.21h27.8v28.57H55.6z"
					/>
					<path fill="#f7b907" d="M83.41 27.8h27.8v27.8h-27.8z" />
					<path fill="#fff" d="M111.21 27.8h27.8v27.8h-27.8z" />
					<path fill="#3987e0" d="M111.21 0h27.8v27.8h-27.8z" />
					<path fill="#e83041" d="M27.8 81.56h27.8v29.64H27.8z" />
				</svg>
			</div>
		</div>
	);
}

// blue --- white
function SectionDividerShapeBlueWhiteSVG({
	className,
	style,
}: SectionDividerRenderProps) {
	return (
		<div className="bricks-between__container container">
			<div className="bricks-between__box">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="139.01"
					height="111.98"
					viewBox="0 0 139.01 111.98"
					className={`bricks-between__img ${className}`}
					style={style}
				>
					<path fill="#e83041" d="M55.6 27.8h27.8v28.99H55.6z" />
					<path fill="#fff" d="M111.21 27.8h27.8v29.94h-27.8z" />
					<path fill="#f7b907" d="M0 54.83h27.8V83.4H0z" />
					<path fill="#ddd" d="M55.6 83.41h27.8v28.57H55.6z" />
					<path fill="#f7b907" d="M83.41 0h27.8v27.8h-27.8z" />
					<path fill="#fff" d="M27.8 0h27.8v27.8H27.8z" />
					<path fill="#3987e0" d="M27.8 54.06h27.8v29.35H27.8z" />
				</svg>
			</div>
		</div>
	);
}

// yellow --- blue
function SectionDividerShapeYellowBlueSVG({
	className,
	style,
}: SectionDividerRenderProps) {
	return (
		<div className="bricks-between__container container">
			<div className="bricks-between__box">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="139.01"
					height="139.78"
					viewBox="0 0 139.01 139.78"
					className={`bricks-between__img ${className}`}
					style={style}
				>
					<path fill="#3987e0" d="M55.6 55.6h27.8v27.8H55.6z" />
					<path
						fill="#2a73bc"
						d="M0 81.86h27.8v29.35H0zM55.6 111.21h27.8v28.57H55.6z"
					/>
					<path fill="#f7b907" d="M83.41 27.8h27.8v27.8h-27.8z" />
					<path fill="#fff" d="M111.21 27.8h27.8v27.8h-27.8z" />
					<path fill="#3987e0" d="M111.21 0h27.8v27.8h-27.8z" />
					<path fill="#e83041" d="M27.8 81.56h27.8v29.64H27.8z" />
				</svg>
			</div>
		</div>
	);
}

export {
	VectorIcon,
	BrickMeLogoSVG,
	SectionDividerShapeBlueWhiteSVG,
	SectionDividerShapeRedBlueSVG,
	SectionDividerShapeRedWhiteSVG,
	SectionDividerShapeWhiteRedSVG,
	SectionDividerShapeYellowBlueSVG,
};
