function optionalEnv(name: string) {
	// const fullName = `REACT_APP_${name}`;
	// return process.env[fullName];
	const fullName = `VITE_${name}`;
	return import.meta.env[fullName];
}

function ensureEnv(name: string) {
	const value = optionalEnv(name);
	if (value === undefined) {
		throw new Error(`Environment variable ${name} missing`);
	}
	return value;
}

export { ensureEnv, optionalEnv };
