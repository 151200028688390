import React from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import type { GtmService } from "frontend-common";
import {
	AuthStateProvider,
	ShopifyGraphQlProvider,
	ApiGraphQlProvider,
	GtmServiceProvider,
} from "hooks";
import { GorgiasProvider } from "hooks/gorgias";
import { DomainProvider, FacebookProvider, ThemeProvider } from "contexts";
import App from "./app";

type AppContainerProps = {
	readonly gtm: GtmService;
	readonly shopifyConfig: {
		readonly customDomain: string;
		readonly storefrontAccessToken: string;
	};
	readonly userPoolConfig: {
		readonly id: string;
		readonly appClientId: string;
	};
	readonly apiUrl: string;
	readonly platformCookieDomain: string;
	readonly creatorAppUrl: string;
	readonly websiteUrl: string;
	readonly gorgiasId: string;
	readonly domain: string;
	readonly originSite: string;
	// readonly countryRedirectRules: ReadonlyArray<CountryRedirectRule>;
	// readonly ipInfoToken: string;
	// readonly defaultCountryCode: string;
	readonly facebookAppId?: string;
	readonly contactEmailAddress: string;
	// readonly theme: Theme;
};

function AppContainer({
	gtm,
	contactEmailAddress,
	shopifyConfig,
	userPoolConfig,
	apiUrl,
	originSite,
	// ipInfoToken,
	platformCookieDomain,
	// defaultCountryCode,
	creatorAppUrl,
	websiteUrl,
	gorgiasId,
	domain,
	facebookAppId,
	// countryRedirectRules,
}: AppContainerProps) {
	return (
		<GtmServiceProvider gtm={gtm}>
			<ThemeProvider
				creatorAppUrl={creatorAppUrl}
				websiteUrl={websiteUrl}
				contactEmailAddress={contactEmailAddress}
			>
				<AuthStateProvider>
					<ApiGraphQlProvider
						originSite={originSite}
						uri={apiUrl}
						userPoolConfig={userPoolConfig}
						platformCookieDomain={platformCookieDomain}
					>
						<ShopifyGraphQlProvider
							shopDomain={shopifyConfig.customDomain}
							storefrontAccessToken={shopifyConfig.storefrontAccessToken}
						>
							{/* <LocalisationProvider
								ipInfoToken={ipInfoToken}
								cookieDomain={platformCookieDomain}
								defaultCountryCode={defaultCountryCode}
								countryRedirectRules={countryRedirectRules}
							> */}
							<GorgiasProvider id={gorgiasId}>
								<HelmetProvider>
									<DomainProvider domain={domain}>
										<FacebookProvider appId={facebookAppId}>
											<BrowserRouter>
												<App />
											</BrowserRouter>
										</FacebookProvider>
									</DomainProvider>
								</HelmetProvider>
							</GorgiasProvider>
							{/* </LocalisationProvider> */}
						</ShopifyGraphQlProvider>
					</ApiGraphQlProvider>
				</AuthStateProvider>
			</ThemeProvider>
		</GtmServiceProvider>
	);
}

export default AppContainer;
