import React, { useLayoutEffect, Suspense, lazy } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import useEffectOnce from "react-use/lib/useEffectOnce";
import Header from "components/Header";
import Footer from "components/Footer";
import {
	isSignedInAuthenticationResult,
	useAuthService,
	useAuthenticationState,
	isLoadingAuthenticationResult,
} from "hooks";
import { LoadingSpinner } from "components/common";

const Home = lazy(() => import("features/home/Home"));
const MyAccount = lazy(() => import("features/user/MyAccount"));
const CompletedDesigns = lazy(() => import("features/user/CompletedDesigns"));
const SavedDesigns = lazy(() => import("features/user/SavedDesigns"));
const Profile = lazy(() => import("features/user/Profile"));
const MyOrders = lazy(() => import("features/user/MyOrders"));

const Login = lazy(() => import("features/auth/Login"));
const SignUp = lazy(() => import("features/auth/SignUp"));
const ForgotPassword = lazy(() => import("features/auth/ForgotPassword"));
const SetupAccount = lazy(() => import("features/auth/SetupAccount"));

const ForbiddenAccess = lazy(() => import("features/error/ForbiddenAccess"));
const InternalServerError = lazy(
	() => import("features/error/InternalServerError"),
);
const PageNotFound = lazy(() => import("features/error/PageNotFound"));

function App() {
	const authResult = useAuthenticationState();
	const authService = useAuthService();

	const { pathname } = useLocation();
	useLayoutEffect(() => window.scrollTo(0, 0), [pathname]);

	useEffectOnce(() => {
		(async () => {
			const user = await authService.loadCurrentUser();
			if (!isLoadingAuthenticationResult(authResult)) {
				throw new Error("Invalid state - already have user");
			}
			authResult.onUserLoaded(user);
		})();
	});

	// Makes a white flash
	// if (isLoadingAuthenticationResult(authResult)) {
	//   return null;
	// }

	return (
		<Switch>
			<Route path={["/error403", "/error404", "/error500"]} exact>
				<Suspense fallback={<LoadingSpinner />}>
					<Switch>
						<Route path="/error403" exact component={ForbiddenAccess} />
						<Route path="/error404" exact component={PageNotFound} />
						<Route path="/error500" exact component={InternalServerError} />
					</Switch>
				</Suspense>
			</Route>

			<Route path="/">
				<Header />
				<Suspense fallback={<LoadingSpinner />}>
					<Switch>
						<Route path="/" component={Home} exact />
						<Route path="/sign-up/" component={SignUp} exact />
						<Route path="/setup-account/" component={SetupAccount} exact />

						{/* Note: need this ternary operator to work. {cond && } within this switch seems to cause error */}
						{/* eslint-disable-next-line no-nested-ternary */}
						{isLoadingAuthenticationResult(authResult) ? (
							<Route
								component={() => (
									<div
										style={{
											padding: 30,
											marginLeft: "auto",
											marginRight: "auto",
										}}
									>
										<LoadingSpinner />
									</div>
								)}
							/>
						) : isSignedInAuthenticationResult(authResult) ? (
							<Switch>
								<Route path="/my-account/" exact component={MyAccount} />
								<Route path="/saved-designs/" exact component={SavedDesigns} />
								<Route
									path="/completed-designs/"
									exact
									component={CompletedDesigns}
								/>
								<Route path="/profile/" exact component={Profile} />
								<Route path="/orders/" exact component={MyOrders} />
								<Redirect path="/login/" to="/my-account/" />
								<Redirect to="/error404" />
							</Switch>
						) : (
							<Switch>
								<Route path="/login/" component={Login} exact />
								<Route
									path="/forgot-password/"
									component={ForgotPassword}
									exact
								/>
								{/* This is the last resort for handling unmatched public routes */}
								<Redirect to="/error404" />
							</Switch>
						)}
					</Switch>
					<Footer />
				</Suspense>
			</Route>
		</Switch>
	);
}

export default App;
