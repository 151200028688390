import type { ReactNode, ReactElement } from "react";
import React, { createContext, useContext } from "react";

type BaseplateSize = number;

type ContextValue = {
	readonly creatorAppUrl: string;
	readonly websiteUrl: string;
	readonly contactEmailAddress: string;
};

const Context = createContext<ContextValue | undefined>(undefined);

type ThemeProviderProps = {
	readonly children: ReactNode;
	readonly creatorAppUrl: string;
	readonly websiteUrl: string;
	readonly contactEmailAddress: string;
};

function ThemeProvider({
	children,
	creatorAppUrl,
	websiteUrl,
	contactEmailAddress,
}: ThemeProviderProps): ReactElement {
	return (
		<Context.Provider
			value={{ websiteUrl, creatorAppUrl, contactEmailAddress }}
		>
			{children}
		</Context.Provider>
	);
}

function useCreatorAppUrl(path?: string) {
	const value = useContext(Context);
	if (!value) {
		throw new Error("No url context");
	}
	if (!path) {
		return value.creatorAppUrl;
	}
	return value.creatorAppUrl + path;
}

function useWebsiteUrl() {
	const value = useContext(Context);
	if (!value) {
		throw new Error("No url context");
	}
	return value.websiteUrl;
}

function useContactEmailAddress() {
	const value = useContext(Context);
	if (!value) {
		throw new Error("No theme context");
	}
	return value.contactEmailAddress;
}

export {
	useContactEmailAddress,
	useCreatorAppUrl,
	useWebsiteUrl,
	ThemeProvider,
};
export type { BaseplateSize };
