import type { ReactNode, RefObject } from "react";
import React, {
	createContext,
	useContext,
	useMemo,
	useEffect,
	useRef,
} from "react";
import type {
	QueryHookOptions,
	QueryResult,
	MutationHookOptions,
	MutationTuple,
} from "@apollo/client/react";
import { useQuery, useMutation } from "@apollo/client/react";
import type {
	OperationVariables,
	DocumentNode,
	DefaultContext,
} from "@apollo/client/core";
import {
	ApolloClient,
	InMemoryCache,
	createHttpLink,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { DateTime, Duration } from "luxon";
import timeoutPromise from "utils/timeout-promise.ts";
import type { AccessToken } from "frontend-common/index.ts";
import type { AuthService } from "frontend-common/auth/create-auth-service.ts";
import createAuthService from "frontend-common/auth/create-auth-service.ts";
import type {
	AuthenticationResult,
	SignedInAuthenticationResult,
} from "./auth-state.tsx";
import {
	isLoadingAuthenticationResult,
	isSignedInAuthenticationResult,
	useAuthenticationState,
} from "./auth-state.tsx";
import { AuthServiceProvider } from "./auth-service.tsx";
import type { TransportProject, PageResult } from "../api-query/viewer.ts";

export const ApiGraphQlContext = createContext<
	ApolloClient<object> | undefined
>(undefined);

type ApiGraphQlProviderProps = {
	readonly uri: string;
	readonly userPoolConfig: {
		readonly id: string;
		readonly appClientId: string;
	};
	readonly platformCookieDomain: string;
	readonly children: ReactNode;
	readonly originSite: string;
};

// If less than a minute left, get new token
const expiryThreshold = Duration.fromMillis(60 * 1000);

async function waitForAccessToken(
	ref: RefObject<AuthenticationResult | undefined>,
): Promise<
	| {
			accessToken: AccessToken;
			signedInState: SignedInAuthenticationResult;
	  }
	| undefined
> {
	const state = ref.current;
	if (!state) {
		return undefined;
	}
	if (isLoadingAuthenticationResult(state)) {
		await timeoutPromise(500);
		return waitForAccessToken(ref);
	}
	if (!isSignedInAuthenticationResult(state)) {
		return undefined;
	}
	return {
		accessToken: state.authUser.jwtAccessToken,
		signedInState: state,
	};
}

export function ApiGraphQlProvider({
	uri,
	userPoolConfig,
	platformCookieDomain,
	children,
	originSite,
}: ApiGraphQlProviderProps) {
	// Ref to get around circular dependency
	const authServiceRef = useRef<AuthService>();

	// Ref to get around constantly re-creating client
	const authStateRef = useRef<AuthenticationResult>();
	const authState = useAuthenticationState();
	useEffect(() => {
		authStateRef.current = authState;
	}, [authState]);

	const apiClient = useMemo(() => {
		const httpLink = createHttpLink({ uri });
		const authLink = setContext(async (_, { headers }) => {
			const access = await waitForAccessToken(authStateRef);
			if (!access) {
				return { headers };
			}
			let { accessToken } = access;
			const { signedInState } = access;
			const diff = DateTime.fromMillis(accessToken.expiry).diffNow();
			if (diff.as("seconds") < expiryThreshold.as("seconds")) {
				const a = authServiceRef.current;
				if (!a) {
					throw new Error("Can't refresh token");
				}
				accessToken = await a.refreshAccessToken(
					signedInState.authUser.jwtRefreshToken.token,
				);
				signedInState.updateAuthenticatedUser({
					jwtAccessToken: accessToken,
				});
			}
			return {
				headers: {
					...headers,
					Authorization: accessToken.token,
				},
			};
		});
		return new ApolloClient({
			link: authLink.concat(httpLink),
			cache: new InMemoryCache({
				typePolicies: {
					User: {
						fields: {
							savedDesigns: {
								// Don't cache separate results based on any of this field's arguments.
								keyArgs: false,
								// Concatenate the incoming list items with the existing list items.
								merge(
									existing: undefined | PageResult<TransportProject>,
									incoming: PageResult<TransportProject>,
								): PageResult<TransportProject> {
									if (!existing) {
										return incoming;
									}
									return {
										...incoming,
										items: [...existing.items, ...incoming.items],
									};
								},
							},
						},
					},
				},
			}),
		});
	}, [uri]);

	// I think this actually happens one frame after the memo change, which could result in an error
	useEffect(
		() => () => {
			apiClient.stop();
			apiClient.resetStore();
		},
		[apiClient],
	);

	const authService = useMemo(() => {
		const newService = createAuthService({
			userPoolId: userPoolConfig.id,
			appClientId: userPoolConfig.appClientId,
			cookieDomain: platformCookieDomain,
			originSite,
		});
		authServiceRef.current = newService;
		return newService;
	}, [userPoolConfig, platformCookieDomain, originSite]);

	return (
		<AuthServiceProvider authService={authService}>
			<ApiGraphQlContext.Provider value={apiClient}>
				{children}
			</ApiGraphQlContext.Provider>
		</AuthServiceProvider>
	);
}

export function useApiApolloClient(): ApolloClient<object> {
	const client = useContext(ApiGraphQlContext);
	if (!client) {
		throw new Error("No api graphql context");
	}
	return client;
}

function useApiQuery<TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	options?: Omit<QueryHookOptions<TData, TVariables>, "client">,
): QueryResult<TData, TVariables> {
	const client = useApiApolloClient();
	return useQuery<TData, TVariables>(query, {
		...options,
		// This makes sure loading is updated when fetchMore
		notifyOnNetworkStatusChange: true,
		client,
	});
}

function useApiMutation<
	TData = any,
	TVariables = OperationVariables,
	TContext = DefaultContext,
>(
	mutation: DocumentNode,
	options?: Omit<MutationHookOptions<TData, TVariables, TContext>, "client">,
): MutationTuple<TData, TVariables, TContext> {
	const client = useApiApolloClient();
	return useMutation<TData, TVariables, TContext>(mutation, {
		...options,
		client: client,
	});
}

export { useApiQuery, useApiMutation };
